<template>
  <div
    class="
      container
      text-center
      d-flex d-column
      align-items-center
      justify-content-center
      pb-5
    "
  >
    <div>
      <img
        class="m-4"
        src="../assets/OHA-logo.png"
        alt=""
        style="width: 150px; height: auto"
      />
      <form class="form-signin" ref="signinFormRef">
        <div class="alert alert-danger" v-if="error">
          {{ error }}
        </div>
        <label class="lead">Username</label>
        <br />
        <input
          type="username"
          id="inputUsername"
          class="form-control"
          required
          autofocus
          v-model.trim="username"
        />
        <br />
        <label class="lead">Password</label>
        <input
          type="password"
          id="inputPassword"
          class="form-control"
          required
          v-model.trim="password"
        />
        <br />
        <div class="d-grid gap-2">
          <button class="btn btn-success" type="submit" @click="login()">
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, Ref } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { AuthActionTypes } from "@/store/modules/auth/constants";

export default defineComponent({
  name: "Login",
  setup() {
    // setup
    const store = useStore();
    const router = useRouter();

    //data
    const username = ref("");
    const password = ref("");
    const error = ref("");

    //element reference
    const signinFormRef: Ref<HTMLFormElement | null> = ref(null);

    //actions
    onMounted(() => {
      signinFormRef.value?.addEventListener("submit", (event) => {
        event.preventDefault();
      });
    });

    const login = async () => {
      error.value = "";
      if (username.value && password.value) {
        await store
          .dispatch(`auth/${AuthActionTypes.LOGIN}`, {
            username: username.value,
            password: password.value,
          })
          .then(() => {
            router.push({ name: "Dashboard" });
          })
          .catch((err) => {
            error.value = err;
          });
      }
    };
    return {
      signinFormRef,
      username,
      password,
      error,
      login,
    };
  },
});
</script>

<style scoped>
.container {
  height: 100vh;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  background-color: rgb(246, 253, 255);
  border: solid;
  border-radius: 2%;
  border-width: thin;
  border-color: rgb(200, 234, 255);
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-control:focus {
  border-color: rgb(200, 234, 255);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(200, 234, 255, 0.6);
}
</style>
