
import { defineComponent, onMounted, Ref } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { AuthActionTypes } from "@/store/modules/auth/constants";

export default defineComponent({
  name: "Login",
  setup() {
    // setup
    const store = useStore();
    const router = useRouter();

    //data
    const username = ref("");
    const password = ref("");
    const error = ref("");

    //element reference
    const signinFormRef: Ref<HTMLFormElement | null> = ref(null);

    //actions
    onMounted(() => {
      signinFormRef.value?.addEventListener("submit", (event) => {
        event.preventDefault();
      });
    });

    const login = async () => {
      error.value = "";
      if (username.value && password.value) {
        await store
          .dispatch(`auth/${AuthActionTypes.LOGIN}`, {
            username: username.value,
            password: password.value,
          })
          .then(() => {
            router.push({ name: "Dashboard" });
          })
          .catch((err) => {
            error.value = err;
          });
      }
    };
    return {
      signinFormRef,
      username,
      password,
      error,
      login,
    };
  },
});
